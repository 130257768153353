<template>
  <b-card
    class="card-app-design rooms-container"
    title="Track Produce"
  >
    <b-row>
      <b-col sm="12">
        <div class="custom-search d-md-flex justify-content-start mb-3">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
            >
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(getStatusText(props.row))">
                {{ getStatusText(props.row) }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="handleOpenViewProduceModal(props.row)">
                    <feather-icon
                      icon="EyeIcon"
                      class="mr-50"
                    />
                    <span>View</span>
                  </b-dropdown-item>
                  <!-- <b-dropdown-item @click="handleOpenEditProduceModal(props.row)">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item> -->
                  <!-- <b-dropdown-item @click="handleOpenManageProduceModal(props.row)">
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span>Manage</span>
                  </b-dropdown-item> -->
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">

              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        <b-modal
          v-model="openManageProduceModal"
          title="Manage Produce"
          size="sm"
          ok-title="Submit"
          @ok.prevent="handleManageProduceStatus"
        >
          <b-row>
            <b-col sm="12">
              <div class="d-flex align-content-center justify-content-center">
                <b-alert
                  variant="warning"
                  show
                  class="py-1 px-1"
                >
                  <p>You're changing the status of {{ ' ' }}
                    <span class="text-primary">{{ produceFormData.config_produce && produceFormData.config_produce.name }}</span>
                    sown on {{ produceFormData.sowing_date }}.
                  </p>
                </b-alert>
              </div>
              <div class="mt-1">
                <b-form-select
                  v-model="selectedStatus"
                  :options="produceStatusOptions"
                />
              </div>
              <div class="mt-1">
                <b-form-textarea
                  v-model="produceFormData.statusNotes"
                  placeholder="Enter Notes"
                />
              </div>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          v-model="openRegisterProduceDetailsModal"
          title="Produce Details"
          size="lg"
          ok-only
        >
          <b-row>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Name:</span><span class="text-info ml-1">{{ produceFormData.config_produce && produceFormData.config_produce.name }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Code:</span><span class="text-info ml-1">{{ produceFormData.config_produce && produceFormData.config_produce.code }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Date Sown:</span><span class="text-info ml-1">{{ produceFormData.sowing_date }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Quantity:</span><span class="text-info ml-1">{{ produceFormData.quantity_sown+" "+produceFormData.quantity_unit }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Land Size:</span><span class="text-info ml-1">{{ produceFormData.land_size+' Acres' }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Growth Period:</span><span class="text-info ml-1">{{ produceFormData.growth_period+" "+produceFormData.growth_unit }}</span></p>
            </b-col>
            <b-col
              sm="12"
              md="6"
            >
              <p><span>Status:</span><span class="text-info ml-1">{{ produceFormData.status }}</span></p>
            </b-col>
            <b-col
              v-if="produceFormData.update_status_note"
              sm="12"
              md="12"
            >
              <p><span>Status Remark:</span><span class="text-info ml-1">{{ produceFormData.update_status_note }}</span></p>
            </b-col>
            <b-col
              sm="12"
            >
              <p><span>Description:</span><span class="text-info ml-1">{{ produceFormData.description }}</span></p>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          v-model="openRegisterProduceModal"
          title="Register Produce"
          size="lg"
          ok-title="Register"
          @ok.prevent="validationForm"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Produce Name"
                      rules="required"
                    >
                      <b-form-select
                        v-model="selectedProduce"
                        :options="registeredProduceData"
                        :state="errors.length > 0 ? false:null"
                        @input="handleSelectProduce"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Produce Code"
                      rules="required"
                    >
                      <b-form-input
                        v-model="produceFormData.code"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Produce Code"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <!-- <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Farm Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="produceFormData.farm"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Farm Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <!-- <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Produce Code"
                      rules="required"
                    >
                      <b-form-input
                        v-model="produceFormData.code"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Produce Code"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> 0752496956-->
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <!-- <label for="example-datepicker">Choose a date</label> -->
                    <validation-provider
                      #default="{ errors }"
                      name="Date Sown"
                      rules="required"
                    >
                      <b-form-datepicker
                        v-model="produceFormData.sowing_date"
                        placeholder="Choose Sowing Date"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Quantity Sown"
                      rules="required"
                    >
                      <b-form-input
                        v-model="produceFormData.quantity_sown"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Quantity Sown (Number)"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Quantity Unit"
                      rules="required"
                    >
                      <b-form-select
                        v-model="selectedProduceUnit"
                        :options="registeredProduceUnit"
                        :state="errors.length > 0 ? false:null"
                        @input="handleSelectQuantityUnit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Land Size"
                      rules="required"
                    >
                      <b-form-input
                        v-model="produceFormData.land_size"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Land Size (In acres)"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Growth Period"
                      rules="required"
                    >
                      <b-form-input
                        v-model="produceFormData.growth_period"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Growth Period"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="6"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Growth Period Unit"
                      rules="required"
                    >
                      <b-form-select
                        v-model="selectedGrowthPeriod"
                        :options="registeredProduceUnit"
                        :state="errors.length > 0 ? false:null"
                        @input="handleSelectGrowthUnit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="12"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Produce Description"
                      rules="required"
                    >
                      <b-form-textarea
                        v-model="produceFormData.description"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Produce Description"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BAlert, BFormSelect, BFormTextarea, BModal, BFormDatepicker, BForm, BPagination, BFormGroup, BFormInput, BDropdownItem, BDropdown,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store/index'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { supabase } from '@/libs/supabaseClient'
// eslint-disable-next-line import/no-cycle
import { getTodayDate } from '@core/utils/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BBadge,
    BModal,
    BForm,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BFormTextarea,
    BFormDatepicker,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      openRegisterProduceModal: false,
      openRegisterProduceDetailsModal: false,
      openManageProduceModal: false,
      selectedStatus: null,
      selectedProduce: null,
      selectedProduceUnit: null,
      selectedGrowthPeriod: null,
      registeredProduceUnit: [
        { value: null, text: 'Select Unit', disabled: true },
      ],
      registeredProduceData: [
        { value: null, text: 'Select Produce', disabled: true },
      ],
      dir: false,
      required,
      email,
      produceFormData: {
        farm_id: 1,
      },
      pages: ['3', '5', '10'],
      produceStatusOptions: [
        { value: null, text: 'Select Status', disabled: true },
        { value: 'Planting', text: 'Planting' },
        { value: 'Growing', text: 'Growing' },
        { value: 'Weeding', text: 'Weeding' },
        { value: 'Prunning', text: 'Prunning' },
        { value: 'Harvesting', text: 'Harvesting' },
        { value: 'Post Harvest', text: 'Post Harvest' },
        { value: 'Storage', text: 'Storage' },
        { value: 'Sold', text: 'Sold' },
      ],
      columns: [
        {
          label: 'Name',
          field(item) {
            return item.config_produce.name
          },
        },
        {
          label: 'Date Sown',
          field: 'sowing_date',
        },
        {
          label: 'Growth Period',
          field(item) {
            return `${item.growth_period} Months`
          },
        },
        {
          label: 'Days to Harvest',
          field: 'days_remaining_to_harvest',
        },
        {
          label: 'Harvesting Date',
          field: 'harvesting_date',
        },
        {
          label: 'Quantity',
          field(item) {
            return item.quantity_sown + item.quantity_unit
          },
        },
        {
          label: 'Remark',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Planting: 'light-info',
        Growing: 'light-info',
        Weeding: 'light-info',
        Prunning: 'light-info',
        Harvesting: 'primary',
        'Post Harvest': 'light-success',
        'Ready To Harvest': 'light-warning',
        Storage: 'light-success',
        Sold: 'light-success',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.handleGetConfigProduce()
    this.handleGetProduce()
  },
  methods: {
    handleOpenRegisterModal() {
      this.produceFormData = {}
      this.openRegisterProduceModal = true
    },
    handleOpenViewProduceModal(item) {
      console.log('::debug item:', item)
      this.openRegisterProduceDetailsModal = true
      this.produceFormData = item
    },
    handleOpenEditProduceModal(item) {
      this.openRegisterProduceModal = true
      this.produceFormData = item
    },
    handleOpenManageProduceModal(item) {
      this.openManageProduceModal = true
      this.produceFormData = item
    },
    handleSelectProduce(item) {
      this.produceFormData.produce_id = item
    },
    handleSelectProduceUnit(item) {
      this.produceFormData.produce_id = item
    },
    handleSelectQuantityUnit(item) {
      this.produceFormData.quantity_unit = item
    },
    handleSelectGrowthUnit(item) {
      this.produceFormData.growth_unit = item
    },
    getStatusText(item) {
      console.log('::debug getStatusText item:', item)
      if (item.days_remaining_to_harvest <= 0) {
        console.log('::debug item ready:', item)
        if (item.status !== 'Harvesting'
              || item.status !== 'Post Harvest'
              || item.status !== 'Storage'
              || item.status !== 'Sold') {
          return 'Ready To Harvest'
        }
        return item.status
      }
      return item.status
    },
    async handleManageProduceStatus() {
      this.$Progress.start()
      try {
        const { error } = await supabase
          .from('produce')
          .update({
            status: this.selectedStatus,
            update_status_note: this.produceFormData.statusNotes,
          })
          .eq('id', this.produceFormData.id)
          .select()
        if (error) {
          this.$Progress.fail()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Update Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else {
          this.$Progress.finish()
          this.openManageProduceModal = false
          this.produceFormData = {}
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Registration Successful',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Data posted successfully',
            },
          })
          this.handleGetProduce()
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error Occurred',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
    getDaysBetweenDates(startDate, endDate) {
      const start = new Date(startDate)
      const end = new Date(endDate)

      // Calculate the time difference in milliseconds
      const timeDifference = end - start

      // Calculate the number of days by dividing the time difference by the number of milliseconds in a day (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

      return daysDifference
    },
    computeDaysRemainingToHarvest(sowingDate, growthPeriod) {
      const growthPeriodDays = growthPeriod * 30
      const todayDate = getTodayDate()
      const dateParts = sowingDate.split('-')
      if (dateParts.length !== 3) {
        return 'Invalid date format. Use YYYY-MM-DD.'
      }

      const year = parseInt(dateParts[0], 10)
      const month = parseInt(dateParts[1], 10) - 1 // Months are 0-indexed in JavaScript (0 = January, 1 = February, ...)
      const day = parseInt(dateParts[2], 10) + 1

      const currentDate = new Date(year, month, day)
      currentDate.setDate(currentDate.getDate() + growthPeriodDays)

      const nextDate = currentDate.toISOString().split('T')[0] // Format the result as YYYY-MM-DD
      const daysRemainingToHarvest = this.getDaysBetweenDates(todayDate, nextDate)
      return { nextDate, daysRemainingToHarvest }
    },
    async handleGetProduce() {
      this.$Progress.start()
      try {
        const { data: response, error } = await supabase
          .from('produce')
          .select(`
            *,
            config_produce (
              id, name, code, description
            )
          `).order('sowing_time', { ascending: false })
        console.log('::debug response:', response)
        if (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Fetch Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else if (response.length > 0) {
          let processedRes = null
          processedRes = response.map(item => {
            const produceItem = item

            const { nextDate, daysRemainingToHarvest } = this.computeDaysRemainingToHarvest(produceItem.sowing_date, produceItem.growth_period)

            produceItem.days_remaining_to_harvest = daysRemainingToHarvest
            produceItem.harvesting_date = nextDate
            return produceItem
          })
          console.log('::debug processedRes:', processedRes)
          this.rows = processedRes
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Fetch Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.$Progress.finish()
      }
    },
    async handleGetConfigProduce() {
      this.$Progress.start()
      try {
        const { data: response, error } = await supabase
          .from('config_produce')
          .select('*')

        const { data: unitResponse, unitError } = await supabase
          .from('config_stock_item_unit')
          .select('*')
        if (error || unitError) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Fetch Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.message,
            },
          })
        } else if (response.length > 0) {
          unitResponse.map(item => this.registeredProduceUnit.push(
            {
              value: item.name,
              text: item.name,
            },
          ))
          response.map(item => this.registeredProduceData.push(
            {
              value: item.id,
              text: item.name,
            },
          ))
        }
      } catch (error) {
        this.$Progress.fail()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Fetch Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      } finally {
        this.$Progress.finish()
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.handleRegisterProduce()
        }
      })
    },

    isEmptyObject(obj) {
      return Object.keys(obj).length === 0
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
